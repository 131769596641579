import Api from '../../../common/services/api';

export const UPLOAD_SCHEDULE = 'schedules/UPLOAD_SCHEDULE';

export default (values) => async (dispatch, getState) => {
  const { network } = getState();

  const formData = new FormData();
  formData.append('week_date', values.week);
  formData.append('schedule', values.schedule);
  if (values.function_id !== 'network') formData.append('function_id', values.function_id);

  const { data } = await Api.post(`/v1/networks/${network.selected}/schedules`, formData);

  dispatch({
    type: UPLOAD_SCHEDULE,
    schedule: data,
  });
};
