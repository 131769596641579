import Api from '../../../common/services/api';

export const DELETE_SCHEDULE = 'schedules/DELETE';

const deleteSchedule = (scheduleId) => ({
  type: DELETE_SCHEDULE,
  scheduleId,
});

/**
 * Delete an uploaded schedule
 * @param {number} scheduleId - Id of the schedule to delete
 * @function callApi
 * @return {Promise} API response
 */
export default function callApi(scheduleId) {
  return (dispatch) => {
    dispatch(deleteSchedule(scheduleId));

    return Api.delete(`/v1/schedules/${scheduleId}`);
  };
}
