import Api from '../../../common/services/api';

export const RECEIVE_SCHEDULES = 'schedules/RECEIVE_SCHEDULES';

export default () => async (dispatch, getState) => {
  const { network: { selected } } = getState();

  const { data } = await Api.get(`/v1/networks/${selected}/schedules`);

  dispatch({
    type: RECEIVE_SCHEDULES,
    items: data,
  });
};
