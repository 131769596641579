import * as React from 'react';
import { connect } from 'react-redux';
import * as R from 'ramda';
import { withTranslation, Trans } from 'react-i18next';
import i18n from '../../../../i18n';
import { pageWrapper, EEventNames } from '../../../../../client/analytics';
import * as AnalyticsService from '../../../../common/services/analytics';
import Overview from '../../../../common/components/overview';
import * as userSelector from '../../../core/selectors/logged-user';
import Container from '../../../../common/components/container';
import { Table } from '../../../../common/components/table';
import { Button } from '../../../../common/components/button';
import Permission from '../../../../common/components/permission';
import Schedule from '../../components/schedule';
import UploadForm from '../../forms/upload';
import * as schedulesSelector from '../../selectors/schedules';
import * as networkSelector from '../../../network/selectors/network';
import { EPermissions } from '../../../../common/definitions';

class SchedulesDefault extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isUploadModalVisible: false,
    };

    this.handleAddSchedule = this.handleAddSchedule.bind(this);
  }

  componentDidMount() {
    this.props.fetchSchedules();
  }

  static props;

  handleAddSchedule(schedule) {
    this.props.change('upload-schedule', 'week', schedule.date, false, false);
    this.props.change('upload-schedule', 'function_id', schedule.value || null, false, false);

    this.setState({ isUploadModalVisible: true });
  }

  render() {
    const { network, schedules, functions, form } = this.props;
    const { isUploadModalVisible } = this.state;

    const columns = [
      {},
      ...schedules[0].data.map((column) => ({
        label: i18n.t('schedules:container_week', { week: column.week }),
        size: '15%',
      })),
    ];
    columns[4].className = 'hidden-sd';
    columns[5].className = 'hidden-sd';

    return (
      <Container name="Schedules">
        <Container.Content>
          <Overview>
            <Overview.Header>
              <Overview.Header.Title><Trans i18nKey="schedules:container_title" /></Overview.Header.Title>
              <Permission name={[EPermissions.NETWORK_FUNCTION_MEMBERSHIPS_SCHEDULES_CREATE, EPermissions.NETWORK_SCHEDULES_CREATE]}>
                <div className="pull-right">
                  <Button onClick={() => this.setState({ isUploadModalVisible: true })} type="primary" iconRight="add">
                    <Trans i18nKey="schedules:container_upload_button" />
                  </Button>
                </div>
              </Permission>
            </Overview.Header>
            <Overview.Content>
              <Table
                columns={columns}
                items={schedules}
                renderRow={({ item: { label, data } }) => [
                  label,
                  ...data.map((schedule) => (
                    <Schedule
                      item={schedule}
                      // $FlowFixMe
                      onAdd={this.handleAddSchedule}
                      onDelete={this.props.deleteSchedule}
                      onOpen={() => AnalyticsService.track(EEventNames.OPENED_SCHEDULE)}
                    />
                  )),
                ]}
              />
            </Overview.Content>
          </Overview>
        </Container.Content>

        <Permission name={[EPermissions.NETWORK_FUNCTION_MEMBERSHIPS_SCHEDULES_CREATE, EPermissions.NETWORK_SCHEDULES_CREATE]}>
          <UploadForm
            key="form"
            show={isUploadModalVisible}
            onClose={() => this.setState({ isUploadModalVisible: false })}
            network={network}
            functions={functions}
            schedules={R.filter(R.prop('id'), R.flatten(R.pluck('data', schedules)))}
            formValues={form?.values}
          />
        </Permission>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  network: networkSelector.selected(state),
  functions: userSelector.functions(state, EPermissions.NETWORK_SCHEDULES_VIEW_ALL),
  schedules: schedulesSelector.schedulesByWeek(state),
  form: state.form['upload-schedule'],
});

const mapDispatchToProps = {
  change: require('redux-form').change,
  fetchSchedules: require('../../actions/fetch-schedules').default,
  deleteSchedule: require('../../actions/delete-schedule').default,
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(pageWrapper(EEventNames.VIEWED_SCHEDULES)(SchedulesDefault)));
