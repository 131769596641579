import * as R from 'ramda';
import moment from 'moment';
import memoize from '../../../common/utils/memoize';
import * as userSelector from '../../core/selectors/logged-user';
import { EPermissions } from '../../../common/definitions';

const weeks = R.range(0, 5);
const currentWeek = moment();

const getSchedules = (state) => state.schedules.schedules.items;
const getFunctions = (state) => state.loggedUser.functions;

const getKey = (week, value) => `${week}-${value}`;
const findData = R.curry((lookup, row) => R.assoc(
  'data',
  R.map((i) => {
    const date = moment(currentWeek).add(i, 'weeks').startOf('isoWeek');

    return lookup[getKey(date.isoWeek(), row.value)] || {
      week: date.isoWeek(),
      date: date.format('YYYY-MM-DD'),
      value: row.value,
    };
  }, weeks),
  row,
));
const addToLookup = (acc, schedule) => R.assoc(
  getKey(schedule.week, schedule.function_id || 'network'),
  schedule,
  acc,
);

export const schedulesByWeek = memoize.createSelector(
  [getSchedules, getFunctions],
  (state, schedules) => {
    const scheduleLookup = R.reduce(addToLookup, {}, schedules);
    const userFunctions = userSelector.functions(state, EPermissions.NETWORK_SCHEDULES_VIEW_ALL);

    const rows = [
      { value: 'network', label: (state.networks.items[state.network.selected] || {}).name },
      ...R.map((item) => ({ value: item.id, label: item.name }), userFunctions),
    ];

    return R.map(findData(scheduleLookup), rows);
  },
);

const getShifts = (state) => state.schedules.shifts.items;

export const shifts = memoize.createSelector(
  [getShifts],
  (state, items) => R.pipe(
    R.values,
    R.map((shift) => R.pipe(
      R.evolve({
        start_time: (string) => new Date(string),
        end_time: (string) => (string
          ? new Date(string)
          : moment(shift.date)
            .add(1, 'day')
            .hours(0)
            .toDate()
        ),
      }),
    )(shift)),
    R.sortBy(R.prop('start_time')),
  )(items),
);

const getSelectedShift = (state) => (state.schedules.shifts.selected
  ? state.schedules.shifts.items[state.schedules.shifts.selected]
  : undefined);

export const selectedShift = memoize.createSelector(
  [getSelectedShift],
  (state, shift) => shift,
);
